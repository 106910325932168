import React, { useState, useEffect } from 'react';

import Cookies from 'js-cookie';
import { Button } from 'reactstrap';
import Logo from './logo.png'
import moment from 'moment-timezone';


import { provinceTaxes } from '../../constants/taxes';
import { Container, Row, Col, ApiUrl, toast, Axios, Modal, ModalHeader, ModalBody, ModalFooter } from '../index';
import { getTimezone } from '../../../src/actions/helper'

export default function ReviewModal({ modal, toggle, deliveries, isAfter7pm, deliveryCharges, redirectPage, activeVendor, storePostalCode, storeProvince, lastScheduledDate }) {
  const [loading, setLoading] = useState(false);

  var total = deliveryCharges.reduce((sum, { fee }) => sum + Number(getPriceWithoutTax(fee)), 0);

  // const total = 700 + 1250 + 900;

  const invalidDeliveryCharge = deliveryCharges.some(({ fee }) => !fee || Number(fee) === 0);
  const getDefault = (value, defaultValue) => value ? value : defaultValue;

  const getPrescriptionNumbers = (delivery) => {
    const prescriptionNumberKeys = [];
    for (let i = 0; i < Number(delivery.numberOfPrescriptions); i++) {
      prescriptionNumberKeys.push(`prescriptionNumber${i + 1}`);
    }
    return prescriptionNumberKeys.map((key) => delivery[key]);
  };

  const submitOrders = async (printLabel = false) => {
    const { API_SUBMIT_ORDERS, API_CREATE_PUROLATOR_LABEL, SUBSCRIBE_WEBHOOK_PUROLATOR } = ApiUrl;

    const orders = deliveries.map((delivery, idx) => {
      const prescriptionNumbers = getPrescriptionNumbers(delivery);


      if (delivery.recurring && delivery.recurring.length > 0 && !(delivery.ends && delivery.ends.length > 0)) {
        // add 6 month to today and add that as end date
        const endDate = moment().add(6, 'months');

        delivery.ends = ['ends'];
        delivery.endDate = endDate.toISOString();
      }
      const addressParts = delivery.address.address.split(',').map((part) => part.trim());
      const country = addressParts.pop();
      const provincePostal = addressParts.pop();
      const [province, ...postalCodeParts] = provincePostal.split(' ');
      const postalCode = delivery.address.zipCode || postalCodeParts.join(' ');
      const city = addressParts.pop();
      const addressLine1 = addressParts.join(', ');
      const packageCodeMapping = {
        envelope: "express_envelope",
        box: "express_box",
        pack: "express_pack",
      };

      const getPackageCode = (size) => packageCodeMapping[size] || undefined;
      const packageCode = getPackageCode(delivery.deliverySize);

      const pharmacyTimeZone = getTimezone(storeProvince);
      const unformattedscheduledDate = getScheduledDate(delivery, activeVendor, pharmacyTimeZone, lastScheduledDate);
      const scheduledDate = unformattedscheduledDate ? unformattedscheduledDate.toISOString() : "";
      return {
        unitNumber: delivery.unitNumber,
        cellPhoneNumber: delivery.phone,
        address: delivery.address.address,
        zipCode: delivery.address.zipCode,
        patientName: delivery.patientName,
        apptOrUnitType: delivery.apptOrUnitType,
        apptOrUnit: delivery.apptOrUnit,
        deliveryNotes: delivery.deliveryNotes,
        pickupNotes: delivery.pickupNotes,
        orderSubTotal: activeVendor === "Uber" ? deliveryCharges[idx].fee : deliveryCharges[idx].fee.toFixed(2),
        pharmacyId: Cookies.get('_id'),
        proofOfDelivery: activeVendor === "Uber" ? delivery.proofOfDelivery : delivery.proofOfDeliveryPurolator,
        proofOfReturnDelivery: delivery.proofOfReturnDelivery,
        proofOfPickup: delivery.proofOfPickup,
        numberOfPrescriptions: delivery.numberOfPrescriptions,
        prescriptionNumbers,
        storePostalCode,
        deliveryFee: deliveryCharges[idx].fee.toFixed(2),
        packages: Array.from(
          { length: delivery.packageOption === "multiple" ? parseInt(delivery.numberOfPackagesSelect || 1) : 1 },
          () => ({
            ...(packageCode
              ? { package_code: packageCode }
              : {
                dimensions: {
                  height: getDefault(parseFloat(delivery.customHeight), 0),
                  width: getDefault(parseFloat(delivery.customWidth), 0),
                  length: getDefault(parseFloat(delivery.customLength), 0),
                  unit: getDefault(delivery.dimensionUnit, "inch"),
                },
              }),
            weight: {
              value: getDefault(parseFloat(delivery.customWeight), 0),
              unit: getDefault(delivery.weightUnit, "pound"),
            },
          })
        ),
        ship_to: {
          name: delivery.patientName,
          email: delivery.patientEmail,
          phone: delivery.phone,
          address_line1: addressLine1,
          city_locality: city,
          state_province: province,
          postal_code: postalCode,
          country_code: country.toLowerCase() === "canada" ? "CA" : country,
          address_residential_indicator: delivery.isDeliverToPharmacy ? "no" : "yes",
        },
        // order schedule
        scheduled: delivery.scheduled,
        scheduledDate: activeVendor === "Purolator" ? scheduledDate : delivery.scheduledDate,
        nextRecurrenceDate: delivery.nextRecurrenceDate,
        costs: delivery.costs,
        recurring: delivery.recurring,
        recurrence: delivery.recursion,
        customRecurringDays: delivery.customRecurringDays,
        customRecurringDaysOrderTime: delivery.customRecurringDaysOrderTime,
        ends: delivery.ends,
        endDate: delivery.endDate,
        isDeliverToPharmacy: delivery.isDeliverToPharmacy,
        isAfter7pm,
        rate_id: deliveryCharges[idx].rate_id,
        fuelFee: deliveryCharges[idx].fuelFee,
        firstPrice: deliveryCharges[idx].carrierFee,
        costTotal: deliveryCharges[idx].fee,
        added_fee: deliveryCharges[idx].added_fee,
      };
    });

    try {
      setLoading(true);
      const loadingToastId = toast.info("Submitting your order, please wait...", { autoClose: false });

      const URL = activeVendor === 'Purolator' ? API_CREATE_PUROLATOR_LABEL : API_SUBMIT_ORDERS;

      const res = await Axios.post(URL, { orders });
      if (!res.data)
        throw new Error("Order submission failed, Contact support for assistance.");



      const { data } = res;
      const createdOrderResponse = data.data
      const createdOrderResponsePurolator = data.deliveries
      if (data.status === 'success') {

        if (createdOrderResponsePurolator && createdOrderResponsePurolator.length > 0) {
          const pharmacyId = Cookies.get('_id');
          if (!pharmacyId) {
            throw new Error("Pharmacy ID is missing, cannot proceed with webhook subscription.");
          }
          try {
            await Promise.all(createdOrderResponsePurolator.map(async (order, index) => {
              const trackingNumber = order.trackingUrl;
              if (!trackingNumber) throw new Error(`Missing tracking number for order ${index + 1}`);

              const subscriptionPayload = { trackingNumber, pharmacyId };
              try {
                await Axios.post(SUBSCRIBE_WEBHOOK_PUROLATOR, subscriptionPayload);
                console.log(`Webhook subscription successful for order ${index + 1}`);
              } catch (error) {
                console.error(`Webhook subscription failed for order ${index + 1}:`, error);
                throw new Error(`Failed to subscribe to webhook for order ${index + 1}`);
              }
            }));

          } catch (error) {
            console.error('Error in processing webhook subscriptions:', error);
          }
        }
        toast.dismiss(loadingToastId);
        toast.success('ORDER CREATED SUCCESSFULLY');
        if (printLabel && activeVendor === "Uber") {
          // Label which pharmacies can print a small one with patient name, Patient address, Phone number, Delivery/dropoff notes, type of droppoff delivery (signature or picture)
          const printData = []
          deliveries.map((delivery, idx) => {
            const addressWithApptOrUnit = delivery.apptOrUnit ? `${delivery.address.address}, ${delivery.apptOrUnitType || "Unit"} ${delivery.apptOrUnit}` : delivery.address.address;

            printData.push({
              ...delivery,
              patientName: delivery.patientName,
              address: addressWithApptOrUnit,
              phone: formatNumberCanadian(delivery.phone),
              deliveryNotes: delivery.deliveryNotes,
              // match with delivery.patientName with createdOrderResponse[index].patientName if match then add createdOrderResponse[].storeOrderId
              storeOrderId: createdOrderResponse.find((order) => order.customerName === delivery.patientName).storeOrderId,
              proofOfDelivery: delivery.proofOfDelivery === "rx" || delivery.proofOfDeliveryPurolator === "rx" ? 'Signature' : 'Picture',
              isDeliverToPharmacy: delivery.isDeliverToPharmacy ? 'Delivery' : 'Dropoff',
              numberOfPrescriptions: delivery.numberOfPrescriptions,
            })
          })
          // Open print page popup with printData
          let printWindow = window.open('', '_blank', 'width=650,height=500');
          printWindow.document.write('<html><head><title>Shipping Label</title></head><body>');
          printWindow.document.write('<style>body { margin: 0; font-size: 24px; } .label { height: 100vh; border: 1px solid black; padding: 20px; box-sizing: border-box; position: relative; } .bold { font-weight: bold; } .logo { position: absolute; bottom: 10px; right: 10px; width: 180px; } .pharmacy { text-align: center; font-size: 28px; } h2 { font-size: 32px; } h3 { font-size: 29px; } p { font-size: 26px; } @media print { @page { size: landscape; } }</style>');

          printData.forEach((data) => {
            printWindow.document.write('<div class="label">');
            printWindow.document.write('<div class="pharmacy"><h2>' + Cookies.get('name') + '</h2></div>');
            printWindow.document.write('<p>Patient Name: ' + data.patientName + '</p>');
            printWindow.document.write('<p>Address: ' + data.address + '</p>');
            printWindow.document.write('<p>Phone: ' + data.phone + '</p>');
            printWindow.document.write('<p>Store Order Id: ' + data.storeOrderId + '</p>');


            if (data.numberOfPrescriptions) {
              if (data.numberOfPrescriptions == 1 && data['prescriptionNumber1'] != "") {
                printWindow.document.write('<p>Number Of Prescriptions: ' + data.numberOfPrescriptions + '</p>');
              } else if (data.numberOfPrescriptions > 1) {
                printWindow.document.write('<p>Number Of Prescriptions: ' + data.numberOfPrescriptions + '</p>');
              }
              for (let i = 1; i <= data.numberOfPrescriptions; i++) {
                const prescriptionNumber = data['prescriptionNumber' + i];
                if (prescriptionNumber) {
                  printWindow.document.write('<p>  - Prescription ' + i + ': ' + prescriptionNumber + '</p>');
                }
              }
            }
            if (data.scheduledDate) {
              printWindow.document.write('<p>Scheduled Date: ' + moment(data.scheduledDate).format('YYYY-MM-DD: hh:mm A') + '</p>');
            }

            printWindow.document.write('<p class="bold">Delivery Notes: ' + data.deliveryNotes + '</p>');
            printWindow.document.write('<img id="logo" class="logo" src="' + Logo + '" width="150" />');
            printWindow.document.write('</div>');
          });

          printWindow.document.write('</body></html>');
          printWindow.document.close();
          // Wait for the logo to load before printing
          const logo = printWindow.document.getElementById('logo');
          if (logo) {
            logo.onload = function () {
              printWindow.print();
            };
          } else {
            toast.error("Logo failed to load.");
          }
        }
        if (printLabel && activeVendor === "Purolator") {
          const printWindow = window.open('', '_blank', 'width=800,height=600');

          if (!printWindow) {
            toast.error('Failed to open print window. Ensure popups are not blocked.');
            return;
          }

          printWindow.document.write('<html><head><title>Print PDFs</title></head><body>');
          printWindow.document.write('<style>iframe { width: 100%; height: 100%; border: none; }</style>');
          deliveries.forEach((delivery) => {
            const deliveryPdf = createdOrderResponsePurolator.find((order) => order.customerName === delivery.patientName).orderUrl;
            if (deliveryPdf)
              printWindow.document.write(
                `<iframe src="${deliveryPdf}" onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px';"></iframe>`
              );

          });


          printWindow.document.write('</body></html>');

          printWindow.document.close();

          printWindow.onload = () => {
            printWindow.print();
          };
        }
        redirectPage();
        return;
      }

      if (data.status === 'failure') {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.error('INVALID_PARAMETERS');
        }

        return;
      } else {
        redirectPage();
      }


    } catch (error) {
      toast.dismiss();
      console.error('Error during order submission:', error);

      const errorMessage =
        (error && error.response && error.response.data && error.response.data.error) ||
        (error && error.message) ||
        'Order submission failed unexpectedly.';
      console.log("error", error)
      toast.error(errorMessage);
    }
    finally {
      setLoading(false);
      toggle(false);
    }
  };

  const getNextWeekday = (date) => {
    const day = date.getDay();
    if (day === 6) {
      date.setDate(date.getDate() + 2);
    }
    else if (day === 0) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  };


  const formatNumberCanadian = (number) => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    if (number.startsWith('+')) {
      const cleaned = ('' + number).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '+' + match[1] + match[2] + '-' + match[3] + '-' + match[4];
      }

    }

  }
  const bgs = ['light', 'white'];

  if (!deliveryCharges || !Array.isArray(deliveryCharges) || deliveryCharges.length === 0) return null;
  return (
    <Modal isOpen={modal} toggle={() => toggle(false)}>
      <ModalHeader>Review Delivery Charges for {activeVendor}</ModalHeader>

      <ModalBody
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <Container>

          {deliveries.filter((delivery) => delivery).map((delivery, idx) => (
            <>
              <ReviewItem
                key={idx}
                bg={bgs[idx % bgs.length]}
                delivery={delivery}
                recurring={delivery.recurring}
                deliveryCharge={deliveryCharges[idx]}
              />
              <Row className="mb-3">
                <Col>
                  <div
                    style={{
                      padding: '0.6rem',
                      boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.1)',
                      marginTop: '1rem',
                    }}
                  >
                    {activeVendor === 'Purolator' && (
                      <p>
                        <b>Expected Delivery:</b> {" "}
                        {deliveryCharges[idx].estimated_delivery_date
                          ? (() => {
                            const baseDate = new Date(deliveryCharges[idx].estimated_delivery_date);
                            if (delivery.scheduledPurolatorDate) {
                              const scheduledDate = new Date(delivery.scheduledPurolatorDate);
                              const dateDifference = Math.floor(
                                (scheduledDate - baseDate) / (1000 * 60 * 60 * 24)
                              );

                              const adjustedDate = new Date(
                                baseDate.setDate(baseDate.getDate() + dateDifference + 1)
                              );

                              const returnDate = (dateDifference < 0)
                                ? new Date(deliveryCharges[idx].estimated_delivery_date)
                                : adjustedDate;

                              const finalDate = getNextWeekday(returnDate);

                              return finalDate.toISOString().split("T")[0];

                            }

                            return baseDate.toISOString().split("T")[0];
                          })()
                          : "Not Available"}


                        {/* <p
                          style={{
                            color: 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          As a result of the Canada Post strike, there may be delays due to high volume of deliveries!
                        </p> */}
                      </p>
                    )}
                    {activeVendor === 'Uber' && (
                      <p><b>Expected Delivery:</b> Delivered Within One Hour of Pickup</p>
                    )}
                    {!['Purolator', 'Uber'].includes(activeVendor) && (
                      <p><b>Expected Delivery:</b> Standard Delivery Time</p>
                    )}
                  </div>
                </Col>
              </Row>
            </>


          ))}
          <br />
          <Row>
            <Col md={{ size: 3, offset: 9 }} className="bg-success pt-3 d-flex justify-content-end align-items-center">
              <h4 style={{ color: 'white', textAlign: 'end' }}>
                Total Fee : <b>${total.toFixed(2)}</b>
              </h4>
            </Col>
          </Row>
        </Container>
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (loading) {
              toast.info("Please wait, order submission is in progress.");
              return;
            }
            if (invalidDeliveryCharge) {
              toast.warn("One or more delivery charges are invalid. Check the amounts.");
              return;
            }
            submitOrders(true);
          }}
          disabled={loading || invalidDeliveryCharge}
        >
          Submit Order(s) & Print Label
        </Button>


        <Button
          color="primary"
          onClick={() => {
            if (loading) {
              toast.info("Please wait, order submission is in progress.");
              return;
            }
            if (invalidDeliveryCharge) {
              toast.warn("One or more delivery charges are invalid. Check the amounts.");
              return;
            }
            submitOrders(false);
          }}
          disabled={loading || invalidDeliveryCharge}
        >
          Submit Order(s)
        </Button>



        <Button color="secondary" onClick={() => toggle(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function ReviewItem({ bg, delivery, deliveryCharge, recurring }) {
  return (
    <Row className={`pt-3 pb-3 bg-${bg} ${deliveryCharge.fee === 0 && 'bg-red'}`}>
      <Col md="9">
        <h3 style={{ marginBottom: 0, marginTop: 5 }}>{deliveryCharge.fee === 0 ? deliveryCharge.message : delivery.patientName}</h3>
        <p style={{ marginBottom: 0 }}>{
          delivery.apptOrUnit ? `${delivery.address.address}, ${delivery.apptOrUnitType || "Unit"} ${delivery.apptOrUnit}` : delivery.address.address
          // delivery.address.address
        }</p>
        {recurring && (
          <span style={{ padding: 3, backgroundColor: '#FFBF00', color: 'white', borderRadius: 5, fontSize: 13, marginBottom: 10 }}>
            Recurring Order
          </span>
        )}
      </Col>
      <Col md="3" className="d-flex justify-content-end align-items-center">
        <h1>${getPriceWithoutTax(deliveryCharge.fee)}</h1>
      </Col>
    </Row>
  );
}

export function getPriceWithoutTax(total) {
  const provinceTax = provinceTaxes[Cookies.get('province')] || 0;
  if (!provinceTax) {
    toast.error("Invalid or missing province tax rate");
    return 0;
  }

  const subTotal = total / (1 + provinceTax / 100);


  return (subTotal / 100).toFixed(2);
}

export function getPriceWithTax(total) {

  const provinceTax = provinceTaxes[Cookies.get('province')] ? provinceTaxes[Cookies.get('province')] : 0;

  return total * (1 + provinceTax / 100);
}

const getScheduledDate = (delivery, activeVendor, pharmacyTimeZone, lastScheduledDate) => {
  // const currentTimeInPharmacy = moment.tz("America/Toronto").set({ hour: 15, minute: 30, second: 0, millisecond: 0 });
  if (activeVendor === "Uber") {
    const parsedDate = moment(delivery.scheduledDate, moment.ISO_8601, true);
    return parsedDate.isValid() ? parsedDate : null;
  }

  const currentTimeInPharmacy = moment.tz(pharmacyTimeZone);
  const todayAtThreePM = currentTimeInPharmacy.clone().set({ hour: 15, minute: 0, second: 0, millisecond: 0 });
  const todayAtFourPM = currentTimeInPharmacy.clone().set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
  const scheduledForTomorrow = currentTimeInPharmacy.clone().add(1, 'day').set({ hour: 15, minute: 0, second: 0, millisecond: 0 });

  if ((delivery.scheduled === undefined && activeVendor === "Purolator")) {
    if (currentTimeInPharmacy.isoWeekday() === 6) {
      toast.info(`The delivery has been scheduled for the next buisness day.`);

      return scheduledForTomorrow.add(1, 'days');
    } else if (currentTimeInPharmacy.isoWeekday() === 7) {
      toast.info(`The delivery has been scheduled for the next buisness day.`);
      return scheduledForTomorrow;
    }
    if (!lastScheduledDate && currentTimeInPharmacy.isBefore(todayAtThreePM)) {
      return todayAtThreePM;
    }
    else if (!lastScheduledDate && currentTimeInPharmacy.isAfter(todayAtThreePM)) {
      toast.info(`Your Pickup will be the scheduled for the next business day`);
      return scheduledForTomorrow;

    }
    const parsedLastScheduledDate = moment.tz(lastScheduledDate, pharmacyTimeZone);

    if (currentTimeInPharmacy.isBefore(todayAtFourPM)) {
      return parsedLastScheduledDate.isValid() ? parsedLastScheduledDate : todayAtThreePM;
    }

    toast.info(`Your Pickup will be the scheduled for the next business day`);

    return scheduledForTomorrow;
  }
  else {
    const parsedDate = moment(delivery.scheduledPurolatorDate);
    return parsedDate.isValid() ? parsedDate : null
  }

};